import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, ReplaySubject, iif, of, zip } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";
import { catchError, first, map, switchMap, take, tap } from "rxjs/operators";
import {
  BillingAccount,
  BillingAccountCheckoutSession, BillingAccountPermissions,
  BillingBundleStatus,
  BillingCreditsBundle,
  BillingCreditUsage,
  BillingProductPackageType,
  CreateBillingAccount,
  CreditType,
  UpdateBillingAccount, UpdateBillingAccountPermissionsInput,
  User
} from '@connect-our-kids/connect-our-kids-lib/generated/graphql';
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";

export interface BillingAccountWithMetadata {
  id?: number;
  teamId?: number;
  name: string;
  description: string;
  emailAddress: string;
  creditsCount: number;
  type: CreditType;
  isDefault: boolean;
}

export interface BillingCreditBundleWithMetadata {
  id: number;
  teamId: number;
  userId: number;
  billingAccountId: number;
  ownerName: string;
  purchaseDate: Date;
  expirationDate: Date;
  creditsCount: number;
  status: BillingBundleStatus;
  active: boolean;
  belongsToPersonalAccount: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  private readonly BILLING_ACCOUNTS_API_URL = environment.API_URL + '/api/billing/accounts';
  private readonly BILLING_CREDIT_BUNDLES_API_URL = environment.API_URL + '/api/billing/credit-bundles';
  private readonly BILLING_CREDIT_USAGES_API_URL = environment.API_URL + '/api/billing/usages';
  private readonly USER_BILLING_CREDIT_USAGES_API_URL = environment.API_URL + '/api/billing/user/usages';
  private readonly BILLING_ACCOUNT_USER_PERMISSIONS = environment.API_URL + '/api/user/billing/permissions';
  private readonly USERS_BILLING_ACCOUNT_PERMISSIONS = environment.API_URL + '/api/billing/permissions';
  private readonly BILLING_CHECKOUT_SESSION_API_URL = environment.API_URL + '/api/billing/checkout-session';
  private readonly BILLING_CUSTOMER_PORTAL_SESSION_API_URL = environment.API_URL + '/api/billing/customer-portal';
  private readonly USER_BILLING_CUSTOMER_PORTAL_SESSION_API_URL = environment.API_URL + '/api/billing/user/customer-portal';

  public billingAccounts = new ReplaySubject<BillingAccountWithMetadata[]>(1);
  public selectedBillingAccount = new ReplaySubject<BillingAccountWithMetadata>(1);

  public billingCreditBundles = new ReplaySubject<BillingCreditBundleWithMetadata[]>(1);

  private loadedBillingAccounts = false;
  private loadedBillingCreditBundles = false;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    @Inject(LOCAL_STORAGE) private webStorage: WebStorageService
  ) {
    this.authService.getUser()
      .pipe(take(1))
      .subscribe(user => {
        if (!user) {
          return;
        }

        this.loadSelectedBillingAccount();
      });
  }

  public getCurrentBillingAccounts(forceReload = false): Observable<BillingAccountWithMetadata[]> {
    return this.getBillingAccounts(forceReload).pipe(take(1));
  }

  public getCurrentSelectedBilingAccount(): Observable<BillingAccountWithMetadata> {
    return this.selectedBillingAccount.pipe(take(1));
  }

  public getSelectedBillingAccount(): Observable<BillingAccountWithMetadata> {
    return this.selectedBillingAccount;
  }

  public setSelectedBillingAccount(billingAccount: BillingAccountWithMetadata): void {
    this.webStorage.set('billingAccountId', billingAccount.id);
    this.selectedBillingAccount.next(billingAccount);
  }

  public getBillingAccounts(forceReload = false): Observable<BillingAccountWithMetadata[]> {
    if (!forceReload && this.loadedBillingAccounts) {
      return this.billingAccounts;
    }

    this.loadedBillingAccounts = true;
    return this.authService.getUser()
      .pipe(
        take(1),
        switchMap((user) => this.loadBillingAccounts(user)),
      );
  }

  public getBillingCreditsUsagesForBillingAccount(teamId: number, billingAccountId: number, startDate: Date, endDate: Date): Observable<BillingCreditUsage[]> {
    return this.httpClient.post<BillingCreditUsage[]>(this.BILLING_CREDIT_USAGES_API_URL, {
      'teamId': teamId,
      'billingAccountId': billingAccountId,
      'startDate': startDate.toISOString(),
      'endDate': endDate.toISOString()
    }, {
      headers: { 'authorization': 'Bearer ' + this.authService.accessToken }
    }).pipe(take(1));
  }

  public getBillingCreditsUsagesForUser(startDate: Date, endDate: Date): Observable<BillingCreditUsage[]> {
    return this.httpClient.post<BillingCreditUsage[]>(this.USER_BILLING_CREDIT_USAGES_API_URL, {
      'startDate': startDate.toISOString(),
      'endDate': endDate.toISOString()
    }, {
      headers: { 'authorization': 'Bearer ' + this.authService.accessToken }
    }).pipe(take(1));
  }

  public getUserBillingAccountPermissions(teamId: number, billingAccountId: number): Observable<BillingAccountPermissions[]> {
    return this.httpClient.post<BillingAccountPermissions[]>(this.USERS_BILLING_ACCOUNT_PERMISSIONS, {
      'teamId': teamId,
      'billingAccountId': billingAccountId
    }, {
      headers: { 'authorization': 'Bearer ' + this.authService.accessToken }
    }).pipe(take(1));
  }

  public updateBillingAccountPermissions(teamId: number, billingAccountId: number, value: UpdateBillingAccountPermissionsInput): Observable<BillingAccountPermissions[]> {
    return this.httpClient.put<BillingAccountPermissions[]>(this.USERS_BILLING_ACCOUNT_PERMISSIONS, {
      teamId,
      billingAccountId,
      value
    }, {
      headers: { 'authorization': 'Bearer ' + this.authService.accessToken }
    }).pipe(take(1));
  }

  public getBillingAccountPermissionsForUser(teamId: number, billingAccountId: number): Observable<BillingAccountPermissions[]> {
    return this.httpClient.get<BillingAccountPermissions[]>(this.BILLING_ACCOUNT_USER_PERMISSIONS, {
      headers: { 'authorization': 'Bearer ' + this.authService.accessToken },
      params: {
        'teamId': teamId,
        'billingAccountId': billingAccountId
      }
    }).pipe(take(1));
  }

  public getBillingCreditsBundles(forceReload = false): Observable<BillingCreditBundleWithMetadata[]> {
    if (!forceReload && this.loadedBillingCreditBundles) {
      return this.billingCreditBundles;
    }

    this.loadedBillingCreditBundles = true;
    return this.getCurrentBillingAccounts()
      .pipe(
        switchMap((accounts) => this.loadBillingCreditBundles(accounts))
      );
  }

  public reloadBillingAccount(id: number): Observable<BillingAccountWithMetadata[]> {
    return iif(() => id == null,
      this.reloadPersonalAccount(),
      this.getBillingAccounts(true)
    );
  }

  public billingCheckoutSession(account: BillingAccountWithMetadata, productPackageType: BillingProductPackageType): Observable<BillingAccountCheckoutSession> {
    const redirectBase = account.teamId ? `${environment.API_URL}/billing/${account.id}` : `${environment.API_URL}/billing/personal`;
    const successUrl = redirectBase + '?paymentCompletedSuccessfully=true';
    const cancelUrl = redirectBase + '?errorOccurredOnPayment=true';

    const requestBody = {
      teamId: account?.teamId,
      billingAccountId: account?.id,
      productPackageType: productPackageType,
      creditType: account.type,
      successUrl,
      cancelUrl
    };

    const requestOptions = { headers: { 'authorization': 'Bearer ' + this.authService.accessToken } };
    return this.httpClient.post<BillingAccountCheckoutSession>(this.BILLING_CHECKOUT_SESSION_API_URL, requestBody, requestOptions);
  }

  public createBillingCustomerSessionUrl(account: BillingAccountWithMetadata): Observable<BillingAccountCheckoutSession> {
    const createPortalUrl = account.teamId ? this.BILLING_CUSTOMER_PORTAL_SESSION_API_URL : this.USER_BILLING_CUSTOMER_PORTAL_SESSION_API_URL;

    const requestBody = {
      teamId: account?.teamId,
      billingAccountId: account?.id,
    };

    const requestOptions = { headers: { 'authorization': 'Bearer ' + this.authService.accessToken } };
    return this.httpClient.post<BillingAccountCheckoutSession>(createPortalUrl, requestBody, requestOptions);
  }

  public createBillingAccount(teamId: number, createBillingAccountInput: CreateBillingAccount): Observable<BillingAccountWithMetadata[]> {
    const requestBody = { teamId, createBillingAccountInput };
    const requestOptions = { headers: { 'authorization': 'Bearer ' + this.authService.accessToken } };

    return this.httpClient.post(this.BILLING_ACCOUNTS_API_URL, requestBody, requestOptions)
      .pipe(
        switchMap(() => this.getBillingAccounts(true))
      );
  }

  public updateBillingAccount(teamId: number, billingAccountId: number, updateBillingAccountInput: UpdateBillingAccount): Observable<BillingAccountWithMetadata[]> {
    const requestBody = { teamId, billingAccountId, updateBillingAccountInput };
    const requestOptions = { headers: { 'authorization': 'Bearer ' + this.authService.accessToken } };

    return this.httpClient.put(this.BILLING_ACCOUNTS_API_URL, requestBody, requestOptions)
      .pipe(
        switchMap(() => this.getBillingAccounts(true))
      );
  }

  public deleteBillingAccount(teamId: number, billingAccountId: number): Observable<BillingAccountWithMetadata[]> {
    const requestOptions = {
      body: { teamId, billingAccountId },
      headers: { 'authorization': 'Bearer ' + this.authService.accessToken }
    };

    return this.httpClient.delete<void>(this.BILLING_ACCOUNTS_API_URL, requestOptions)
      .pipe(
        switchMap(() => this.getBillingAccounts(true))
      );
  }

  public activateUserBillingCreditsBundle(bundleId: number): Observable<BillingCreditsBundle> {
    const requestBody = { bundleId };
    const requestOptions = { headers: { 'authorization': 'Bearer ' + this.authService.accessToken } };
    const requestUrl = this.BILLING_CREDIT_BUNDLES_API_URL + '/activate-personal-bundle';

    return this.httpClient.post<BillingCreditsBundle>(requestUrl, requestBody, requestOptions)
      .pipe(
        switchMap((bundle) =>
          this.getBillingCreditsBundles(true)
            .pipe(
              first(),
              map(() => bundle)
            )
        )
      );
  }

  public activateTeamBillingCreditsBundle(teamId: number, billingAccountId: number, bundleId: number): Observable<BillingCreditsBundle> {
    const requestBody = { teamId, billingAccountId, bundleId };
    const requestOptions = { headers: { 'authorization': 'Bearer ' + this.authService.accessToken } };
    const requestUrl = this.BILLING_CREDIT_BUNDLES_API_URL + '/activate';

    return this.httpClient.post<BillingCreditsBundle>(requestUrl, requestBody, requestOptions)
      .pipe(
        switchMap((bundle) =>
          this.getBillingCreditsBundles(true)
            .pipe(
              first(),
              map(() => bundle)
            )
        )
      );
  }

  private reloadPersonalAccount(): Observable<BillingAccountWithMetadata[]> {
    return zip(
      this.authService.reloadUser().pipe(take(1)),
      this.getCurrentBillingAccounts()
    ).pipe(
      tap(([user, accounts]) => {
        const newPersonalAccountState = {
          name: "Personal account",
          emailAddress: user.email,
          description: '',
          creditsCount: user.creditsCount,
          type: CreditType.PERSONAL,
          isDefault: false
        };

        const newAccounts = accounts.filter(account => account.type == CreditType.BILLING_ACCOUNT);
        newAccounts.unshift(newPersonalAccountState);

        this.billingAccounts.next(newAccounts);
      }),
      switchMap(() => this.billingAccounts.asObservable()));
  }

  private loadBillingAccounts(user: User): Observable<BillingAccountWithMetadata[]> {
    return this.httpClient.get<BillingAccount[]>(this.BILLING_ACCOUNTS_API_URL, {
      headers: { 'authorization': 'Bearer ' + this.authService.accessToken }
    }).pipe(
      catchError(() => of([])),
      tap((accounts: BillingAccount[]) => {
        const accountsWithMetadata = accounts.map(account => {
          return {
            id: account.id,
            teamId: account.teamId,
            name: account.name,
            description: account.description,
            emailAddress: account.emailAddress,
            type: CreditType.BILLING_ACCOUNT,
            creditsCount: account.creditsCount,
            isDefault: account.isDefault
          } as BillingAccountWithMetadata
        });

        const personalBillingAccount = {
          name: "Personal account",
          emailAddress: user.email,
          description: '',
          creditsCount: user.creditsCount,
          type: CreditType.PERSONAL,
          isDefault: true
        } as BillingAccountWithMetadata;
        accountsWithMetadata.unshift(personalBillingAccount);

        this.billingAccounts.next(accountsWithMetadata);
      }),
      switchMap(() => this.billingAccounts),
    );
  }

  private loadBillingCreditBundles(accounts: BillingAccount[]): Observable<BillingCreditBundleWithMetadata[]> {
    return this.httpClient.get<BillingCreditsBundle[]>(this.BILLING_CREDIT_BUNDLES_API_URL, {
      headers: { 'authorization': 'Bearer ' + this.authService.accessToken }
    }).pipe(
      catchError(() => of([])),
      tap((bundles: BillingCreditsBundle[]) => {
        const bundlesWithTeamName = bundles.map(bundle => {
          const account = accounts.find((account) => bundle.billingAccountId === account.id);
          const ownerName = account?.name || 'Personal account';
          return {
            id: bundle.id,
            teamId: bundle.teamId,
            userId: bundle.userId,
            billingAccountId: bundle.billingAccountId,
            ownerName,
            purchaseDate: bundle.createdAt,
            expirationDate: bundle.expirationDate,
            creditsCount: bundle.creditsCount,
            status: bundle.status,
            active: BillingBundleStatus.ACTIVE === bundle.status,
            belongsToPersonalAccount: bundle.userId != null
          } as BillingCreditBundleWithMetadata
        });

        this.billingCreditBundles.next(bundlesWithTeamName);
      }),
      switchMap(() => this.billingCreditBundles),
    );
  }

  private loadSelectedBillingAccount(): void {
    const selectedBillingAccountId = this.getSelectedBillingAccountIdFromStorage();
    this.getCurrentBillingAccounts().subscribe((accounts) => {
      let selectedAccount: BillingAccountWithMetadata;
      if (selectedBillingAccountId) {
        selectedAccount = accounts.find((account) => selectedBillingAccountId === account.id);
      }

      if (!selectedAccount) {
        selectedAccount = accounts.find((account) => account.isDefault);
      }

      if (!selectedAccount) {
        selectedAccount = accounts[0];
      }

      this.setSelectedBillingAccount(selectedAccount);
    });
  }

  private getSelectedBillingAccountIdFromStorage(): number {
    return this.webStorage.get('billingAccountId');
  }
}
