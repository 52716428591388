export const environment = {
  production: false,
  API_URL: "https://dev.search.connectourkids.org",
  FC_API_URL: "https://family-staging.connectourkids.org",
  APP_URL: "https://dev.search.connectourkids.org",
  AUTH0_CLIENT_ID: "JUR6JVt9N4sn8bHrSXR818rwrj91hsTb",
  AUTH0_AUDIENCE: "https://api-staging.connectourkids.org/",
  APP_NAME: "People Search DEV",
  SENTRY_ENABLED: true,
  ENVIRONMENT_NAME: "dev"
};
