import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { BillingAccountWithMetadata, BillingService } from '../billing.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit, OnDestroy {

  public loadingAccounts = false;

  public selectedBillingAccount: BillingAccountWithMetadata | undefined;
  public personalAccount: BillingAccountWithMetadata | undefined;
  public teamBillingAccounts: BillingAccountWithMetadata[] | undefined;

  private readonly ON_DESTROY = new Subject<void>();

  constructor(
    private auth: AuthService,
    private billingService: BillingService
  ) {
  }

  public ngOnInit(): void {
    this.loadingAccounts = true;
    this.billingService.getBillingAccounts()
      .subscribe(billingAccounts => {
        this.personalAccount = billingAccounts.find(account => account.name === 'Personal account');
        this.teamBillingAccounts = billingAccounts.filter(account => account.teamId);
        this.loadingAccounts = false;
      });

    this.billingService.selectedBillingAccount
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe(account => this.selectedBillingAccount = account);
  }

  public ngOnDestroy(): void {
    this.ON_DESTROY.next();
    this.ON_DESTROY.complete();
  }

  public selectBillingAccount(account: BillingAccountWithMetadata): void {
    this.billingService.setSelectedBillingAccount(account);
  }

  public isAuthenticated(): boolean {
    return this.auth.isAuthenticated();
  }

}
