<ng-container>
  <ng-container *ngIf="!loadingAccount; else loadingTemplate">
    <billing-details [isPersonalAccount]="false" [selectedBillingAccount]="selectedAccount"></billing-details>
  </ng-container>
</ng-container>

<ng-template #loadingTemplate>
  <div class="loader">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</ng-template>
