<div class="sub-header" *ngIf="isAuthenticated()">
  <div class="d-flex justify-content-end align-items-center mr-1">
    <span class="label">Select billing account</span>
    <div ngbDropdown>
      <button [disabled]="loadingAccounts" type="button" class="btn btn-primary" id="billingDropdown" ngbDropdownToggle>
        {{selectedBillingAccount ? selectedBillingAccount.name : "Billing Accounts"}}
      </button>
      <div *ngIf="!loadingAccounts" ngbDropdownMenu aria-labelledby="billingDropdown">
        <label>Personal</label>
        <p class="mb-0" ngbDropdownItem (click)="selectBillingAccount(personalAccount)">{{personalAccount.name}}<span class="search-number-label">({{personalAccount.creditsCount}} searches)</span></p>
        <label>Team</label>
        <p class="mb-0" ngbDropdownItem (click)="selectBillingAccount(teamAccount)" *ngFor="let teamAccount of teamBillingAccounts">{{teamAccount.name}}<span class="search-number-label">({{teamAccount.creditsCount}} searches)</span></p>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  Loading...
</ng-template>
