import { BillingBundleStatus, BillingCreditsBundle } from '@connect-our-kids/connect-our-kids-lib/generated/graphql';
import { BillingCreditBundleWithMetadata } from '../billing.service';

/**
 * Sorts bundles by putting the EMPTY and EXPIRED bundles at the bottom and sorting the other bundles by their expiration date, ascending.
 */
export const sortBillingAccountBundlesByExpirationDate = (bundles: BillingCreditBundleWithMetadata[]): BillingCreditBundleWithMetadata[] => {
  return [...bundles].sort(sortByStatusAndExpirationDate);
};

const sortByStatusAndExpirationDate = (bundle1: BillingCreditsBundle, bundle2: BillingCreditsBundle): number => {
  const bundle1Date = new Date(bundle1.expirationDate);
  const bundle2Date = new Date(bundle2.expirationDate);
  if (bundle1.status === bundle2.status) {
    return bundle1Date.getTime() > bundle2Date.getTime() ? 1 : -1;
  }

  if (bundle1.status === BillingBundleStatus.EMPTY || bundle1.status === BillingBundleStatus.EXPIRED) {
    return 1;
  } else if (bundle2.status === BillingBundleStatus.EMPTY || bundle2.status === BillingBundleStatus.EXPIRED) {
    return -1;
  }

  return bundle1Date.getTime() > bundle2Date.getTime() ? 1 : -1;
};
