<div class="container billing-details-page p-0 mt-4" *ngIf="!loadingBillingAccountInfo; else loadingTemplate">

  <div class="row">
    <div class="col-lg-12 col-md-12 mb-4">
        <a class="back-btn" [routerLink]="['/billing']"><img src="../../assets/left-arrow.png"> <span>Go back to Billing</span></a>
    </div>
    <div class="col-lg-12 col-md-12">
      <div class="summary-wrapper">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-row bd-highlight">
              <div class="px-2 bd-highlight"><p class="summary-title">Billing Details</p></div>
              <div class="ml-auto px-2 bd-highlight"><p class="summary-title">Actions</p></div>
            </div>
            <div class="d-flex flex-row bd-highlight mb-3 billing-details">
              <div class="pl-2 pr-4 bd-highlight">
                <p class="summary-header">Account Name</p>
                <p class="summary-data primary-color">{{selectedBillingAccount.name}}</p>
              </div>
              <div class="px-4 bd-highlight">
                <p class="summary-header">Email address</p>
                <p class="summary-data">{{selectedBillingAccount.emailAddress}}</p>
              </div>
              <div class="px-4 bd-highlight">
                <p class="summary-header">Description</p>
                <p class="summary-data desc-data">{{selectedBillingAccount.description ? selectedBillingAccount.description : '--'}}</p>
              </div>
              <div class="px-4 bd-highlight">
                <p class="summary-header">Credits</p>
                <p class="summary-data">{{selectedBillingAccount.creditsCount}}</p>
              </div>
              <div class="px-2 d-flex flex-column bd-highlight ml-auto">
                <button *ngIf="isUserManager"
                        [disabled]="isCreatingCustomerSession"
                        (click)="openCustomerPortalSessionForAccount()"
                        class="btn btn-primary mb-1 summary-btn">Customer Portal</button>
                <button [disabled]="!isUserManager" *ngIf="isUserManager || userWithPermissions.canManageBundles" (click)="openAddCreditsToBillingAccountModal()" class="btn btn-primary mb-1 summary-btn">Add more credits</button>
                <button *ngIf="!isPersonalAccount && isUserManager" (click)="openPermissionsModal()" class="btn btn-primary mb-1 summary-btn">Permissions</button>
                <button *ngIf="!isPersonalAccount" [disabled]="!isUserManager" (click)="openEditBillingAccountModal()"  class="btn btn-primary mb-1 summary-btn">Edit</button>
                <button *ngIf="!isPersonalAccount" [disabled]="!isUserManager" (click)="openDeleteBillingAccountConfirmationModal()" class="btn btn-primary summary-btn">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row pt-3">
    <div class="col-12 px-0">
        <div class="bundles-table">
          <div class="d-flex align-items-center mt-5 mb-4">
            <a class="bundles-title">Bundles</a>
          </div>
          <table class="table table-hover" mat-table [dataSource]="bundles">
            <ng-container matColumnDef="index">
              <th mat-header-cell *matHeaderCellDef> Index </th>
              <td mat-cell *matCellDef="let element"> {{element.index}} </td>
            </ng-container>

            <ng-container matColumnDef="purchaseDate">
              <th mat-header-cell *matHeaderCellDef> Purchase Date </th>
              <td mat-cell *matCellDef="let element"> {{element.purchaseDate | date: 'MM/dd/YYYY HH:mm'}} </td>
            </ng-container>

            <ng-container matColumnDef="expirationDate">
              <th mat-header-cell *matHeaderCellDef> Expiration Date </th>
              <td mat-cell *matCellDef="let element"> {{element.expirationDate | date: 'MM/dd/YYYY HH:mm'}} </td>
            </ng-container>

            <ng-container matColumnDef="creditsCount">
              <th mat-header-cell *matHeaderCellDef> Number of Searches Remaining </th>
              <td mat-cell *matCellDef="let element"> {{element.creditsCount}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element"> {{element.status | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="active">
              <th mat-header-cell *matHeaderCellDef> Active </th>
              <td mat-cell *matCellDef="let element">
                <mat-slide-toggle
                        [disabled]="activatingBundle || element.status !== BillingBundleStatus.NEW || (!userWithPermissions.canManageBundles && !isUserManager)"
                        [ngModel]="element.status === BillingBundleStatus.ACTIVE"
                        (change)="activateBundle(element)"
                        class="example-margin">
                </mat-slide-toggle>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="CREDIT_BUNDLES_TABLE_COLUMNS"></tr>
            <tr mat-row *matRowDef="let row; columns: CREDIT_BUNDLES_TABLE_COLUMNS;"></tr>
          </table>
          <mat-paginator #bundlesPaginator [pageSizeOptions]="[5, 10, 20]"
                         showFirstLastButtons
                         aria-label="Select page of billing bundles">
          </mat-paginator>
        </div>

<!--      TODO add usage history endpoints -->
        <div class="history-section mt-5 pt-2">
          <div class="history-title">Usage History</div>
          <table *ngIf="!loadingBillingAccountInfo else loadingTemplate" class="table table-hover"  mat-table [dataSource]="usages">
            <ng-container matColumnDef="index">
              <th mat-header-cell *matHeaderCellDef> Index </th>
              <td mat-cell *matCellDef="let element"> {{element.index}} </td>
            </ng-container>

            <ng-container matColumnDef="creditsUsed">
              <th mat-header-cell *matHeaderCellDef> Credits Used </th>
              <td mat-cell *matCellDef="let element"> 1 </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef> Date </th>
              <td mat-cell *matCellDef="let element"> {{element.createdAt | date:'MM/dd/YYYY HH:mm'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="CREDIT_USAGES_TABLE_COLUMNS"></tr>
            <tr mat-row *matRowDef="let row; let i = dataIndex; columns: CREDIT_USAGES_TABLE_COLUMNS;"></tr>
          </table>

          <mat-paginator #usagesPaginator [pageSizeOptions]="[5, 10, 20]"
                         showFirstLastButtons
                         aria-label="Select page of credit usages">
          </mat-paginator>
        </div>
    </div>
  </div>
</div>

<ng-template #billingAccountSettingsModal id="create-billing-modal" class="modal">
  <div class="wrapper">
    <div class="modal-wrapper">
      <form [formGroup]="billingAccountForm">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Edit Billing Account</h4>
        </div>
        <div class="modal-body py-3">
          <div class="pb-4">
            <label class="mb-1">Name of the Account</label>
            <input type="text" class="form-control" formControlName="name" placeholder="Name of the Account" />
            <span class="error-txt"
              *ngIf="billingAccountForm.controls.name.touched && billingAccountForm.controls.name.errors">Please
              provide a name for the account!</span>
          </div>

          <div class="pb-4">
            <label class="mb-1">Email Address</label>
            <input type="email" class="form-control" formControlName="emailAddress" placeholder="Email Address" />
            <span class="error-txt"
              *ngIf="billingAccountForm.controls.emailAddress.touched && billingAccountForm.controls.emailAddress.errors">{{emailError}}</span>
          </div>

          <div class="pb-3">
            <label class="mb-1">Description</label>
            <textarea type="text" class="form-control" formControlName="description"
              placeholder="Description"></textarea>
            <span class="error-txt"
              *ngIf="billingAccountForm.controls.description.touched && billingAccountForm.controls.description.errors">Please
              provide a description for the account!</span>
          </div>
          <input class="switch mr-2" type="checkbox">
          Is default
          <button type="button" class="tooltip-btn" ngbTooltip="Set as default account if you want all of your team to use this as primary account.">
            <img src="../../assets/info.png">
          </button>
        </div>
        <div class="modal-footer justify-content-between">
          <button [disabled]="loadingBillingAccountInfo" type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="saveBillingAccount()"
            [disabled]="billingAccountFormHasError || savingBillingAccount">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #deleteBillingAccountConfirmationModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirm Delete</h4>
  </div>
  <div class="modal-body py-4">
        <p class="mb-0">Are you sure you want to delete this Billing Account?</p>
  </div>
  <div class="modal-footer justify-content-between">
    <button [disabled]="deletingBillingAccount" type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
    <button [disabled]="deletingBillingAccount" type="button" class="btn btn-danger" (click)="deleteBillingAccount()">Delete</button>
  </div>
</ng-template>

<ng-template #addCreditsModal id="demo-modal" class="modal">
  <div class="wrapper">
    <div class="modal-wrapper">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Choose your bundle</h4>
      </div>
      <div class="modal-body py-4">
        <p class="mb-3">Select one of the bundles below and then click continue</p>
        <div class="row">
          <div class="col-md-6" (click)="selectBundleToPurchase(BillingProductPackageType.PEOPLE_SEARCH_1_CREDIT)" [class.selected]="selectedBundle === BillingProductPackageType.PEOPLE_SEARCH_1_CREDIT">
            <div class="card">
              <div class="card-content">
                <p class="mb-0 gray-txt">Individual searches</p>
                <h4>$2.99/search</h4>
              </div>
            </div>
          </div>
          <div class="col-md-6" (click)="selectBundleToPurchase(BillingProductPackageType.PEOPLE_SEARCH_100_CREDITS)" [class.selected]="selectedBundle === BillingProductPackageType.PEOPLE_SEARCH_100_CREDITS">
            <div class="card">
              <div class="card-content">
                <p class="mb-0 gray-txt">Bundles of 100 searches</p>
                <h4>$99/bundle</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
        <button type="button" class="btn btn-primary main-user-button" [disabled]="!selectedBundle || redirectingToStripe"
          (click)="openCheckoutSession(true)">Continue</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #billingPermissionModal id="create-billing-modal" class="modal">
  <div class="wrapper">
    <div class="permission-modal-wrapper">
      <div>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Billing Permission</h4>
        </div>
        <div class="modal-body py-5">
          <div class="row mb-2 mt-3">
            <div class="col-3 d-flex">
              <div class="modal-desc">Team members</div>
            </div>
            <div class="col-3 d-flex justify-content-center">
              <div class="modal-desc">Use account</div>
            </div>
            <div class="col-3 d-flex justify-content-center">
              <div class="modal-desc">Add credits</div>
            </div>
            <div class="col-3 d-flex justify-content-center">
              <div class="modal-desc">Manage</div>
            </div>
        </div>
        <div class="row border-t" *ngFor="let user of usersWithPermissions">
          <div class="col-3 d-flex">
            <div>{{user.fullName}}</div>
          </div>
            <div class="col-3 d-flex justify-content-center">
              <input [disabled]="user.id === userWithPermissions.id" [(ngModel)]="user.canUseAccount" type="checkbox" class="permission-checkbox">
            </div>
            <div class="col-3 d-flex justify-content-center">
              <input [disabled]="user.id === userWithPermissions.id" [(ngModel)]="user.canManageBundles" type="checkbox" class="permission-checkbox">
            </div>
            <div class="col-3 d-flex justify-content-center">
            <input [disabled]="user.id === userWithPermissions.id" [(ngModel)]="user.canManageAccount" type="checkbox" class="permission-checkbox">
          </div>
        </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
          <button [disabled]="savingPermissions" (click)="saveBillingAccountPermissions()" type="button" class="btn btn-primary">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template class="container p-0 mt-4" #loadingTemplate>
  <div class="loader">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</ng-template>
