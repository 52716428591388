import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {SearchComponent} from './search/search.component';
import {AuthErrorComponent} from './auth-error/auth-error.component';
import {AuthVerifyEmailComponent} from './auth-verify-email/auth-verify-email.component';
import {CallbackComponent} from './callback/callback.component';
import {AuthAccessDeniedComponent} from './auth-access-denied/auth-access-denied.component';
import {LoggedOutComponent} from './logged-out/logged-out.component';
import { KycNotVettedComponent } from './kyc-not-vetted/kyc-not-vetted.component';
import { KycDeniedComponent } from './kyc-denied/kyc-denied.component';
import { BillingComponent } from './billing/billing.component';
import { BillingAccountsComponent } from './billing-accounts/billing-accounts.component';
import { PersonalBillingDetailsComponent } from './personal-billing-details/personal-billing-details.component';
import { TeamBillingAccountDetailsComponent } from './team-billing-account-details/team-billing-account-details.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'kyc-not-vetted', component: KycNotVettedComponent},
  { path: 'kyc-denied', component: KycDeniedComponent },
  { path: 'search', component: SearchComponent},
  { path: 'auth-error', component: AuthErrorComponent},
  { path: 'verify-email', component: AuthVerifyEmailComponent},
  { path: 'callback', component: CallbackComponent},
  { path: 'request-access', component: AuthAccessDeniedComponent},
  { path: 'logged-out', component: LoggedOutComponent},
  { path: 'billing',
    component: BillingComponent,
    children: [
      { path: '', component: BillingAccountsComponent },
      { path: 'personal', component: PersonalBillingDetailsComponent },
      { path: ':id', component: TeamBillingAccountDetailsComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
