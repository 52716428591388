import { BrowserModule } from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { NgbModule, NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { RootComponent } from './root/root.component';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule} from '@angular/common/http';
import { SearchComponent } from './search/search.component';
import { AuthService } from './auth.service';
import { HeaderComponent } from './header/header.component';
import { CallbackComponent } from './callback/callback.component';
import { AuthErrorComponent } from './auth-error/auth-error.component';
import { AuthVerifyEmailComponent } from './auth-verify-email/auth-verify-email.component';
import { AuthEmailVerifiedComponent } from './auth-email-verified/auth-email-verified.component';
import { AuthAccessDeniedComponent } from './auth-access-denied/auth-access-denied.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { FooterComponent } from './footer/footer.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { TeamComponent } from './team-users/team.component';

import * as Sentry from '@sentry/browser';
import {environment} from '../environments/environment';
import {SentryErrorHandler} from './handler/sentry.handler';
import { KycNotVettedComponent } from './kyc-not-vetted/kyc-not-vetted.component';
import { KycDeniedComponent } from './kyc-denied/kyc-denied.component';
import { BundlesComponent } from './bundles/bundles.component';
import { BillingComponent } from './billing/billing.component';
import { BillingAccountsComponent } from './billing-accounts/billing-accounts.component';
import { BillingDetailsComponent } from './billing-details/billing-details.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { PersonalBillingDetailsComponent } from './personal-billing-details/personal-billing-details.component';
import { TeamBillingAccountDetailsComponent } from './team-billing-account-details/team-billing-account-details.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

if (environment.SENTRY_ENABLED) {
  Sentry.init({
    dsn: 'https://b54a99cd8c9c4fe4968307ae9b294d8d@o378845.ingest.sentry.io/5218811',
    environment: environment.ENVIRONMENT_NAME
  });
}

@NgModule({
  declarations: [
    HomeComponent,
    RootComponent,
    SearchComponent,
    HeaderComponent,
    CallbackComponent,
    AuthErrorComponent,
    AuthVerifyEmailComponent,
    AuthEmailVerifiedComponent,
    AuthAccessDeniedComponent,
    LoggedOutComponent,
    FooterComponent,
    SearchFormComponent,
    KycNotVettedComponent,
    KycDeniedComponent,
    TeamComponent,
    BundlesComponent,
    BillingComponent,
    BillingAccountsComponent,
    BillingDetailsComponent,
    PersonalBillingDetailsComponent,
    TeamBillingAccountDetailsComponent,
    SubHeaderComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgbNavModule,
    MatSlideToggleModule,
    MatTableModule,
    MatPaginator
  ],
  providers: [AuthService, {provide: ErrorHandler, useClass: SentryErrorHandler}, provideAnimationsAsync()],
  bootstrap: [RootComponent]
})
export class AppModule { }
