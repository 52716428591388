import { Component, OnDestroy, OnInit } from '@angular/core';
import { BillingAccountWithMetadata, BillingService } from '../billing.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { parseInt } from 'lodash';

@Component({
  selector: 'team-billing-details',
  templateUrl: './team-billing-account-details.component.html',
})
export class TeamBillingAccountDetailsComponent implements OnInit, OnDestroy {
  public selectedAccount: BillingAccountWithMetadata;
  public loadingAccount: boolean;

  private readonly ON_DESTROY = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private billingService: BillingService,
  ) {
  }

  public ngOnDestroy(): void {
    this.ON_DESTROY.next();
    this.ON_DESTROY.complete();
  }

  public ngOnInit(): void {
    this.loadingAccount = true;
    const selectedAccountId = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));

    this.billingService
      .getBillingAccounts()
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe(billingAccounts => {
        const selectedAccount = billingAccounts.find(account => account.id === selectedAccountId);
        if (!selectedAccount) {
          this.router.navigate(['billing']);
          return;
        }

        this.selectedAccount = selectedAccount;
        this.loadingAccount = false;
      });
  }
}
