<div class="row searchType">
  <div class="col-12 d-flex justify-content-between align-items-center">
    <div>
      <span>Search By: </span>
      <a href="javascript:void(0)" [class.selectedSearchType]="searchType == SearchType.NAME" (click)="setSearchType(SearchType.NAME)">Name</a>
      <a href="javascript:void(0)" [class.selectedSearchType]="searchType == SearchType.EMAIL" (click)="setSearchType(SearchType.EMAIL)">Email</a>
      <a href="javascript:void(0)" [class.selectedSearchType]="searchType == SearchType.ADDRESS" (click)="setSearchType(SearchType.ADDRESS)">Address</a>
      <a href="javascript:void(0)" [class.selectedSearchType]="searchType == SearchType.PHONE" (click)="setSearchType(SearchType.PHONE)">Phone</a>
      <a href="javascript:void(0)" [class.selectedSearchType]="searchType == SearchType.URL" (click)="setSearchType(SearchType.URL)">URL</a>
    </div>
    <div>
      <app-sub-header></app-sub-header>
    </div>
  </div>
</div>
<div class="row searchRow">

      <div class="col-md-12 col-lg-9 ">
          <form [formGroup]="searchForm" (ngSubmit)="onSearch()" >

            <div class="row">

              <div class="col-12">
                <div class="searchFormInputsWrapper">

                  <div class="row">
                    <div class="col-md-12  searchInputWrapper"
                        [class.col-lg-8]="searchType === SearchType.NAME">
                      <input type="text"
                             formControlName="main"
                             class="searchInput"
                             placeholder="{{generateSearchHint(searchType)}}"
                             (keyup.enter)="onSearch()"
                             autofocus />
                    </div>
                    <div class="col-md-12 col-lg-4 locationInputWrapper"
                    *ngIf="searchType === SearchType.NAME">
                      <input type="text"
                             formControlName="location"
                             class="locationInput"
                             placeholder="City, State"
                             (keyup.enter)="onSearch()" />
                    </div>
                  </div>
                </div>
                <div 
                  *ngIf="showRelationshipInput && searchType === SearchType.NAME" 
                  class="searchFormInputsWrapper"
                >
                  <div class="row">
                    <div class="col-md-12 relationshipInputWrapper">
                      <input 
                        type="text"
                        formControlName="relationship"
                        class="relationshipInput"
                        placeholder="Relationship name optional"
                        (keyup.enter)="onSearch()"    
                      />
                    </div>
  
                  </div>
                </div>
                <div *ngIf="searchType === SearchType.NAME">
                  <a 
                    href="javascript:void(0)" 
                    (click)="toggleRelationshipInput()"
                  >{{showRelationshipInput ? 'Hide' : 'Add'}} Relationship</a>
                </div>
              </div>
            </div>

          </form>

      </div>
      <div class="col-md-12 col-lg-3 addressExplainer" *ngIf="searchType == SearchType.ADDRESS">
        Use a comma to separate address, city, state, and country<br/>
      </div>
      <div class="col-md-12 col-lg-3">
          <button type="button" class="btn btn-primary searchButton" (click)="onSearch()">Search</button>
          <button type="button" class="btn btn-secondary searchButton" (click)="startOverClick()">Start Over</button>

      </div>
</div>
