
<div class="container-fluid p-0">

  <div class="noSearchRow">

      <app-header></app-header>
      
      <div class="container mt-5">
        <app-search-form></app-search-form>

        <div class="row">
            <div class="col-12">
                  <h1>People Search</h1>
            </div>
        </div>
  
        <div class="row">
          <div class="col-xs-12 col-sm-8 col-md-6">
            Social workers, family recruiters, and CASA volunteers use this tool to find contact information for extended families and supporters of foster kids. This search uses public information from over 300 sources and covers over 3 billion people.
          </div>
        </div>
  
        <div class="row videoLinkRow">
          <div class="col-12">
            <a href="javascript:void(0)" (click)="openIntroductionVideo()">Watch a 3 minute quick introduction video</a>
          </div>
        </div>
      </div>
  </div>

  <div class="container">
    <app-footer></app-footer>
  </div>
</div>

<ng-template #videoModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <iframe class="videoIframe" width="100%" src="https://www.youtube.com/embed/anAtK7cepeI?rel=0&autoplay=1&modestbranding=1&controls=1&wmode=transparent" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div class="loader">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</ng-template>