import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillingAccountWithMetadata } from '../billing.service';
import { Subject } from 'rxjs';
import { AuthService } from '../auth.service';
import { takeUntil } from 'rxjs/operators';
import { CreditType } from '@connect-our-kids/connect-our-kids-lib/generated/graphql';


@Component({
  selector: 'personal-billing-details',
  templateUrl: './personal-billing-details.component.html',
})
export class PersonalBillingDetailsComponent implements OnInit, OnDestroy {

  public selectedAccount: BillingAccountWithMetadata;
  public loadingAccount: boolean;

  private readonly ON_DESTROY = new Subject<void>();

  constructor(private modal: NgbModal, private authService: AuthService
  ) {
  }

  public ngOnDestroy(): void {
    this.ON_DESTROY.next();
    this.ON_DESTROY.complete();
  }

  public ngOnInit(): void {
    this.loadingAccount = true;

    this.authService.user
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe((user) => {
        this.selectedAccount = {
          name: 'Personal account',
          emailAddress: user.email,
          description: '',
          creditsCount: user.creditsCount,
          type: CreditType.PERSONAL,
          isDefault: false
        };

        this.loadingAccount = false;
      });
  }
}
